.keyboard-key {
  width: 30px;
  height: 30px;
  padding: 0;
}

.keyboard-key-image {
  width: 100%;
  height: 100%;
  padding: 0;
}

.keyboard-toggle-button {
  margin-bottom: 10px;
}

.keyboard-container {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.keyboard-row {
  align-items: center;
  gap: 5px;
  display: flex;
}

.input-image {
  height: 30px;
  margin-right: 5px;
}

.editable-div {
  border: 1px solid #ccc;
  min-height: 100px;
  margin-bottom: 1em;
  padding: 5px;
  overflow-y: auto;
}

.editable-input {
  color: #495057;
  cursor: text;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-width: 50%;
  max-width: 100%;
  min-height: 2em;
  margin-bottom: 1em;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
  overflow: auto;
}

.editable-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.editable-input:empty:before {
  content: attr(data-placeholder);
  color: #6c757d;
}

.editable-input img.input-image {
  vertical-align: middle;
  height: 1.5em;
  margin: 0 2px;
}

.keyboard-key.function-key {
  color: #212529;
  background-color: #f8f9fa;
  min-width: 60px;
  font-weight: bold;
}

.keyboard-key.clear-key {
  background-color: #ffc107;
}

.keyboard-key.backspace-key {
  color: #fff;
  background-color: #dc3545;
}
/*# sourceMappingURL=index.cd89a817.css.map */
