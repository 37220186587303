.keyboard-key {
  padding: 0;
  width: 30px;
  height: 30px;
}

.keyboard-key-image {
  padding: 0;
  width: 100%;
  height: 100%;
}

.keyboard-toggle-button {
  margin-bottom: 10px;
}

.keyboard-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.keyboard-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.input-image {
  height: 30px;
  margin-right: 5px;
}

.editable-div {
  border: 1px solid #ccc;
  min-height: 100px;
  padding: 5px;
  overflow-y: auto;
  margin-bottom: 1em;
}

.editable-input {
  display: inline-block;
  min-width: 50%;
  max-width: 100%;
  min-height: 2em;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: text;
  overflow: auto;
  margin-bottom: 1em;
}

.editable-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.editable-input:empty::before {
  content: attr(data-placeholder);
  color: #6c757d;
}

.editable-input img.input-image {
  height: 1.5em;
  vertical-align: middle;
  margin: 0 2px;
}

.keyboard-key.function-key {
  background-color: #f8f9fa;
  color: #212529;
  font-weight: bold;
  min-width: 60px;
}

.keyboard-key.clear-key {
  background-color: #ffc107;
}

.keyboard-key.backspace-key {
  background-color: #dc3545;
  color: white;
}
